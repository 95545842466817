import React from "react"
import { Link } from "gatsby"
import { Alert } from "react-bootstrap"

import Seo from "../components/seo"

const SponsorSuccessPage = () => (
  <>
    <Seo title="Success" />
    <Alert variant='success'>
      Payment Success!
    </Alert>
    <h3>Thank you for your support!</h3>
    <Link to="/">Go back to the Home Page</Link>
  </>
)

export default SponsorSuccessPage
